import { ClerkProvider } from '@clerk/nextjs';
import { useTheme } from 'next-themes';
import React from 'react'
import { dark } from '@clerk/themes';
import { useRouter } from 'next/router';

function AuthProvider({ children }) {
	const clerkConfig = {
		apiKey: process.env.CLERK_SECRET_KEY,
		frontendApi: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
	};
	const { theme } = useTheme();
	const router = useRouter();
	return (
		<ClerkProvider publishableKey={clerkConfig.frontendApi}

			navigate={(to) => router.push(to)}
			appearance={{
				baseTheme: theme === 'dark' || theme === 'system' ? dark : null
			}}
		>{children}
		</ClerkProvider>
	)
}

export default AuthProvider