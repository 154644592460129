import "../styles/globals.css";
import { ThemeProvider, useTheme } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import UserContext from "../components/UserContext";
import React, { useEffect, useRef, useState } from "react";
import config from 'react-reveal/globals';
import AuthProvider from "../AuthProvider";
// import PreLoader from "../components/PreLoader";
import client from "../AppoloClient";
import { ApolloProvider } from "@apollo/client";
import { Analytics } from '@vercel/analytics/react';




function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });
  config({ ssrFadeout: true })

  // const [contentLoaded, setContentLoaded] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setContentLoaded(true);
  //   }, 2000);
  // }, []);

  return (
    <>
      <Provider store={store}>

        <ThemeProvider enableSystem={true} attribute="class">
          <AuthProvider>
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                <ApolloProvider client={client}>
                  {/* {contentLoaded ? */}
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                  {/* :
                    <PreLoader />
                  } */}
                </ApolloProvider>
              </UserContext.Provider>
            </MetaMaskProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
      <Analytics />
    </>
  );
}

export default MyApp;
