import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileModalhide, walletModalShow } from "../../redux/counterSlice";
import { FaAd, FaBlog, FaCartPlus, FaCommentAlt, FaSignOutAlt, FaDiscord, FaSignInAlt, FaFacebookF, FaInstagram, FaLinkedinIn, FaWallet, FaHome, FaCube } from "react-icons/fa";
import { BsWindowStack } from 'react-icons/bs'
import { FaStar } from "react-icons/fa";

import { useRouter } from "next/router";
import { SignOutButton, useAuth } from "@clerk/nextjs";
import SearchBar from "../hero/Searchbar";
import Link from "next/link";

const Profile_modal = () => {

  const profileModal = useSelector((state) => state.counter.profileModal);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false)
  const [resources, setResources] = React.useState(false)

  const router = useRouter()
  const { userId } = useAuth();

  async function navigateTo(path) {
    await router.push(path)
    dispatch(profileModalhide())

  }

  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={profileModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
            <div className="modal-header">
              <h5 className="modal-title" id="profileModalLabel">
                {/* Choose your wallet */}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(profileModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6 ">

              <SearchBar className='z-[2]' />

              <button
                onClick={() => setOpen(!open)}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900   flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md"
              >
                <div className="flex gap-2 items-center"><FaCube className="mb-1" />  <span
                  className={
                    open
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >
                  Domains
                </span>
                </div>
                <i className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className={`h-4 w-4 dark:fill-accent transition-all duration-300 ${open ? 'rotate-180' : 'rotate-0'}`}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              {open && <div className="font-display dark:text-white text-jacarta-900 flex flex-col items-start justify-between py-3.5 text-base  px-5 w-full border border-accent  border-t-0 bg-accent/10 rounded-md" >
                {/* <p className="py-2 text-base tracking-wider text-md">Top-Level (TLD)</p>
                <p className="py-2 text-base tracking-wider text-md">Sub-Level (TLD)</p> */}
                <p className="py-2 text-base tracking-wider text-md flex gap-2">
                  <FaStar style={{ fill: "url(#blue-gradient)" }} />
                  Coming soon</p>


              </div>}

              {/* <button
                onClick={() => setResources(!resources)}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900   flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md mt-2"
              >
                <div className="flex gap-2 items-center"><BsWindowStack className="mb-1 dark:fill-accent fill-jacarta-900" />  <span
                  className={
                    resources
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >
                  Resources
                </span>
                </div>
                <i className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className={`h-4 w-4 dark:fill-accent transition-all duration-300 ${open ? 'rotate-180' : 'rotate-0'}`}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              {resources && <div className="font-display  dark:text-white text-jacarta-900    py-3.5 text-base  px-5 w-full border border-accent  border-t-0   bg-accent/10 rounded-md" >
                <div class="grid grid-cols-2 gap-2 ">
                  <div onClick={() => navigateTo('/blogs')} class="flex items-center gap-2 p-3 rounded-md transition-all duration-150
                   cursor-pointer   ">
                    <FaBlog />
                    <span className="text-jacarta-900 dark:text-white text-sm md:text-base">Blog </span>
                  </div>
                  <div onClick={() => navigateTo('/news')} class="flex items-center gap-2 p-3 rounded-md transition-all duration-150
                   cursor-pointer   ">
                    <FaBlog />
                    <span className="text-jacarta-900 dark:text-white text-sm md:text-base">News </span>

                  </div>
                </div>
              </div>} */}

              <div
                onClick={() => navigateTo('/giveaways')}
                className="dropdown-toggle font-display dark:text-accent text-jacarta-900 cursor-pointer  flex items-center justify-between py-3.5 text-base  px-5 w-full border border-accent  bg-accent/10 rounded-md mt-2"
              >
                <span className="flex gap-2 items-center"><FaCommentAlt className="mb-1 " />  <span
                  className={
                    resources
                      ?
                      "text-jacarta-900 dark:text-accent"
                      : ""
                  }
                >
                  {/* Enterprise */}
                  Giveaways
                </span>
                </span>

              </div>
              <div class="grid grid-cols-2 md:grid-cols-2 gap-4 mt-2 ">
                <div class="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  " onClick={() => dispatch(walletModalShow())}>
                  <div className=" rounded-full ">
                    <FaWallet className="text-accent" />
                  </div>
                  <span className="text-jacarta-900 dark:text-white">Wallet</span>
                </div>

                <div class="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  ">
                  <div className="  rounded-full">
                    <FaCartPlus className="text-accent" />
                  </div> <span className="text-jacarta-900 dark:text-white">Cart </span>
                </div>

                {!userId ? <div onClick={() => navigateTo('/signin')} class="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  ">
                  <div className="  rounded-full">
                    <FaSignInAlt className="text-accent" />
                  </div> <span className="text-jacarta-900 dark:text-white">Login </span>
                </div>
                  :
                  <SignOutButton signOutCallback={() => navigateTo('/')} class="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  ">
                    <div className="  rounded-full">
                      <FaSignOutAlt className="text-accent" />

                      <span className="text-jacarta-900 dark:text-white">Signout </span>

                    </div>
                  </SignOutButton>
                }


                {/* <Link href='/' class="flex items-center  p-3 py-2 rounded-md transition-all duration-150  cursor-pointer border-[1px] border-[#00000014] hover:bg-accent/20 dark:border-[#ffffff14]  w-full gap-2  ">
                  <div className="  rounded-full">
                    <FaHome className="text-accent" />
                  </div> <span className="text-jacarta-900 dark:text-white">Signout </span>
                </Link> */}
              </div>

              {/* <div class="grid grid-cols-2 gap-2 mt-10">
                <div class="flex items-center gap-2 p-3 rounded-md transition-all duration-150 dark:hover:bg-accent/10 hover:bg-accent/10 border-2 border-[#00000030] dark:border-[#ffffff30]  cursor-pointer bg-white dark:bg-jacarta-700 "> <div className="dark:bg-jacarta-600   bg-jacarta-800  p-2 rounded-full">
                  <FaGlobe className="text-white" />
                </div>  <span className="text-jacarta-900 dark:text-white text-sm md:text-base">Domains</span></div>
                <div class="flex items-center gap-2 p-3 rounded-md transition-all duration-150 dark:hover:bg-accent/10 hover:bg-accent/10 border-2 border-[#00000030] dark:border-[#ffffff30]  cursor-pointer bg-white dark:bg-jacarta-700  "> <div className="dark:bg-jacarta-600   bg-jacarta-800  p-2 rounded-full">
                  <FaBlog className="text-white" />
                </div> <span className="text-jacarta-900 dark:text-white text-sm md:text-base">Blog </span> </div>
                <div class="flex items-center gap-2 p-3 rounded-md transition-all duration-150 dark:hover:bg-accent/10 hover:bg-accent/10 border-2 border-[#00000030] dark:border-[#ffffff30]  cursor-pointer bg-white dark:bg-jacarta-700  "> <div className="dark:bg-jacarta-600   bg-jacarta-800  p-2 rounded-full">
                  <FaAd className="text-white" />
                </div> <span className="text-jacarta-900 dark:text-white text-sm md:text-base">Resources </span> </div>
                <div class="flex items-center gap-2 p-3 rounded-md transition-all duration-150 dark:hover:bg-accent/10 hover:bg-accent/10 border-2 border-[#00000030] dark:border-[#ffffff30]  cursor-pointer bg-white dark:bg-jacarta-700  "> <div className="dark:bg-jacarta-600   bg-jacarta-800  p-2 rounded-full">
                  <FaCommentAlt className="text-white" />
                </div> <span className="text-jacarta-900 dark:text-white text-sm md:text-base">Contact </span> </div>
              </div> */}
              <div className="flex justify-center gap-6 mt-[30px] mb-[20px]">
                <a href="https://discord.com/channels/netzylo/" target="_blank" >
                  <FaDiscord className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://www.facebook.com/NetZyloInc/" target="_blank" >
                  <FaFacebookF className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://twitter.com/NetZylo/" target="_blank">
                  <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:fill-gray-300 transition-all  duration-500  hover:-translate-y-1 fill-white"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>

                </a>
                <a href="https://instagram.com/netzylo/" target="_blank">
                  <FaInstagram className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                </a>
                <a href="https://www.linkedin.com/company/netzylo/" target="_blank">
                  <FaLinkedinIn className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                </a>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div >
  );
};

export default Profile_modal;
