import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import Header02 from "./header/Header02";
import Header03 from "./header/Header03";
import Header04 from "./header/Header04";
import Profile_modal from "./modal/Profile_Modal";
import { useAuth } from "@clerk/nextjs";
import { Toaster } from "react-hot-toast";
import { useTheme } from "next-themes";
import { useDispatch } from "react-redux";

import Coming_Soon_Modal from "./modal/coming_soon_Modal";
import React from "react";
import { comingSoonModalShow } from "../redux/counterSlice";

export default function Layout({ children }) {
  const route = useRouter();
  // header start
  let header;
  if (
    route.asPath === "/home/home_3" ||
    route.asPath === "/home/home_9" ||
    route.asPath === "/maintenance" ||
    route.asPath === "/home/home_12"
  ) {
    header = <Header02 />;
  } else if (route.asPath === "/platform_status") {
    header = <Header03 />;
  } else if (route.asPath === "/home/home_8") {
    header = <Header04 />;
  } else {
    header = <Header01 />;
  }
  // header end
  const { userId, } = useAuth();

  const darkTheme = {
    border: '2px solid #02f6d1',
    background: '#101436',
    color: '#fff'

  }

  const lightTheme = {
    border: '2px solid #02f6d1',
    background: '#fff',
    color: '#101436'

  }
  const { theme } = useTheme();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore")
    setTimeout(() => {
      if (!visitedBefore) {
        dispatch(comingSoonModalShow())
      }
    }, 15000);
  }, [])

  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
          style: theme === 'dark' || theme === 'system' ? { ...darkTheme } : { ...lightTheme }
          ,
        }}

        position="top-center"
        reverseOrder={false}
      />
      <Header04 />
      <Wallet_modal />
      <Profile_modal />
      <BidsModal />
      <BuyModal />
      <Coming_Soon_Modal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
