import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { walletModalhide } from "../../redux/counterSlice";
import Link from "next/link";
import { BsArrowRight } from 'react-icons/bs'
import Image from "next/image";


const Wallet_modal = () => {
  const walletModal = useSelector((state) => state.counter.walletModal);
  const dispatch = useDispatch();
  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={walletModal ? "block modal fade show p-4 z-[9999999] " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full  m-auto">
          <div className="modal-content gradient-box  dark:bg-jacarta-900">
            <div className="modal-header border-0">
              <h5 className="modal-title" id="walletModalLabel">
                Choose your wallet
              </h5>

              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(walletModalhide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body text-center  p-4">
              <button className="items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center w-full mb-4">Coming soon</button>
              <div className="mb-6">
                <div className="grid grid-cols-4 gap-3">
                  <div className="flex items-center gap-2 dark:border-[#ffffff14] border-[#00000014] border-[1px]      bg-white  dark:bg-jacarta-900 hover:dark:bg-accent/20 hover:bg-accent/20  cursor-pointer transition-all duration-200 p-1 my-2 rounded-md justify-center">
                    <div className="  h-[3.5rem] w-[3.5rem] ">
                      <Image
                        width={86}
                        height={86}
                        src={'/images/wallets/metamask.svg'}
                        className=" rounded-full "
                        alt="wallet"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2 dark:border-[#ffffff14] border-[#00000014] border-[1px]    bg-white  dark:bg-jacarta-900 hover:dark:bg-accent/20 hover:bg-accent/20  cursor-pointer transition-all duration-200  p-1 my-2 rounded-md justify-center ">
                    <div className="h-[3.5rem] w-[3.5rem] ">
                      <Image
                        width={86}
                        height={86}
                        src={'/images/wallets/coinbase.svg'}
                        className="  rounded-full "
                        alt="wallet"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2 dark:border-[#ffffff14] border-[#00000014]  border-[1px]    bg-white  dark:bg-jacarta-900 hover:dark:bg-accent/20 hover:bg-accent/20  cursor-pointer transition-all duration-200  p-1 my-2 rounded-md justify-center ">
                    <div className="h-[3.5rem] w-[3.5rem] ">
                      <Image
                        width={86}
                        height={86}
                        src={'/images/wallets/wallet_connect.svg'}
                        className=" rounded-full "
                        alt="wallet"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-2 dark:border-[#ffffff14] border-[#00000014] border-[1px]    bg-white  dark:bg-jacarta-900 hover:dark:bg-accent/20 hover:bg-accent/20  cursor-pointer transition-all duration-200  p-1 my-2 rounded-md justify-center ">
                    <div className="h-[2rem] w-[2rem] flex items-center ">
                      <Image
                        width={46}
                        height={46}
                        src={'/images/wallets/paper_wallet.svg'}
                        className="h-[2.5rem] w-[2.5rem] object-contain mx-auto rounded-full "
                        alt="wallet"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="dark:bg-jacarta-900  dark:border-jacarta-600 border-jacarta-900 border-1 border-b-0   border bg-white  mb-0 border-x-0 text-center transition-shadow hover:shadow-lg mt-[2.5rem]"
                >
                </div>
                <div
                  className="dark:border-jacarta-600  text-sm flex items-center justify-center border-jacarta-900 mx-auto mb-3  -mt-[1.2rem] h-[2.5rem] w-[2.5rem] rounded-full border bg-white dark:bg-jacarta-900 text-jacarta-900 dark:text-white"
                  alt="wallet"
                > OR</div>
              </div>


              <div className="flex justify-center w-full items-center mb-4 relative">
                <input type="email" placeholder="Enter your email address" className="hover:ring-accent/10 focus:ring-accent rounded-md dark:bg-jacarta-700 w-full h-[3.2rem]" />
                <Link href={'profile/user_avatar'} className="absolute right-2 group font-bold    flex items-center p-1 rounded-full dark:bg-jacarta-600  hover:dark:bg-jacarta-500 bg-jacarta-800 hover:bg-jacarta-600  transition-all duration-200  ">
                  <BsArrowRight className="w-[1.2rem] h-[1.2rem] text-jacarta-500  group-hover:dark:text-jacarta-900 transition-all duration-200" />
                </Link>
              </div>
            </div>
            {/* <!-- end body --> */}

            <Link href="/" className="dark:text-accent text-jacarta-900 hover:underline text-center inline-block py-6">Continue as Guest</Link>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet_modal;
