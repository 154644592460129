import Link from "next/link";
import Image from "next/image";
import { useTheme } from "next-themes";
import { Fade } from "react-reveal";
import { FaDiscord, FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { useRef } from "react";
import { useFormspark } from "@formspark/use-formspark";
import Loading from "./Loading";

const footer = () => {
  const { theme } = useTheme();

  const formRef = useRef();
  const FORMSPARK_FORM_ID = "HPEVBpuX";
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {};
    const formElements = formRef.current.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {
        formData[element.name] = element.value;
      }
    }
    await submit({ ...formData });
    toast.success("Thanks for subscribing!")
    formRef.current.reset()

  };

  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="container px-[20px] lg:px-0 dark:bg-jacarta-900 page-footer bg-white relative overflow-hidden">
        <Fade ssrReveal delay={350} cascade>
          <div className="absolute -bottom-[30%] -left-[15%] hidden dark:block ">
            <div className="relative z-10 bg-white dark:bg-jacarta-900">
              <div className="footer-background z-[inherit] hidden dark:block"></div>
              {/* <div className="footer-background-2 z-[inherit] block dark:hidden"></div> */}

              <div className="h-[500px] animate-fade "
                style={{
                  mixBlendMode: theme === "dark" ? "lighten" : "difference",
                }}
              >
                <Image
                  height={200}
                  width={1200}
                  className="object-cover"
                  src="/images/hero/hero-space.png"
                  alt="Xhibiter | NFT Marketplace"
                />

              </div>
            </div>
          </div>
          <div className="container !relative z-20 ">
            <div className="grid grid-cols-1 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-2">
              <div className=" ">
                <div className="relative">
                  {/* <!-- Logo --> */}
                  <Link href="#" className="inline-block">
                    <Image
                      width={130}
                      height={28}
                      src="/images/logo.png"
                      className="dark:hidden"
                      alt="Xhibiter | NFT Marketplace"
                    />
                  </Link>

                  <Link href="#" className=" mb-6 inline-block">
                    <Image
                      width={130}
                      height={28}
                      src="/images/logo_white.png"
                      className="hidden dark:block"
                      alt="Xhibiter | NFT Marketplace"
                    />
                  </Link>
                  <p className="dark:text-white mb-6 w-3/4">
                    Navigating the future of the internet with Web3 Domains. Decentralize everything, everywhere!
                  </p>
                  <form ref={formRef} onSubmit={onSubmit} className="  z-20  mr-auto backdrop-blur w-full max-w-[380px]">
                    {/* <p className="dark:text-white text-center text-base ">Join our newsletter to stay up to date on futures and releases.</p> */}
                    <div>
                      <input type="email" placeholder="Enter your Email..." required={true} className="rounded-lg placeholder:dark:text-white bg-jacarta-500 dark:bg-jacarta-600 w-full py-3 px-6 my-6" />
                    </div>
                    <button type="submit" className={`text-center justify-center rounded-md text-[18px] font-medium  py-[10px] px-[32px] md:py-[12px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 w-full block md:inline  ${!submitting ? " bg-gradient-to-tr from-hotPink via-purplish to-accent" : " disabled:text-transparent disabled:bg-jacarta-800"}  relative `}
                      disabled={submitting}
                    >
                      {submitting ? <Loading size={5} /> : "Connect with us"}</button>
                  </form>
                  {/* <!-- Socials --> */}

                </div>

              </div>
              <div className="md:border md:border-l-jacarta-600 md:border-r-0 md:border-y-0 px-28">
                <p className="text-lg dark:text-white pb-10">Menu</p>
                <div className="pb-6">
                  <Link href="/" className="hover:text-accent text-lg">Domains</Link>
                </div>
                {/* <div className="pb-6">
                  <Link href="/blogs" className="hover:text-accent text-lg">Blog</Link>
                </div>
                <div className="pb-6">
                  <Link href="/news" className="hover:text-accent text-lg">News</Link>
                </div>
                <div className="pb-6">
                  <Link href="/giveaways" className="hover:text-accent text-lg">Giveaways</Link>
                </div> */}
                <div className="pb-6">
                  <Link href="/contact" className="hover:text-accent text-lg">Contact</Link>
                </div>
              </div>

            </div>


          </div>
        </Fade>
      </footer >

      <div className="container px-[20px] lg:px-0 !py-0">
        <Fade ssrReveal delay={350} cascade>
          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-white text-sm">
              <span> 2023 NetZylo Inc. | Powered by AI</span>

            </span>
            <div className="flex space-x-5">

              <a target="_blank" href="https://www.facebook.com/NetZyloInc/" className="group cursor-pointer">
                <FaFacebookF className=" dark:fill-jacarta-200 group-hover:fill-accent h-5 w-5 " />
              </a>
              <a target="_blank" href="https://twitter.com/NetZylo/" className="group cursor-pointer">
                <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" className=" dark:fill-jacarta-200 group-hover:fill-accent h-5 w-5 "><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>

              </a>
              <a target="_blank" href="https://instagram.com/netzylo/" className="group cursor-pointer">
                <FaInstagram className=" dark:fill-jacarta-200 group-hover:fill-accent h-5 w-5 " />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/netzylo/" className="group cursor-pointer">
                <FaLinkedinIn className=" dark:fill-jacarta-200 group-hover:fill-accent h-5 w-5 " />
              </a>
              <a target="_blank" href="https://discord.com/channels/netzylo/" className="group cursor-pointer">
                <FaDiscord className=" dark:fill-jacarta-200 group-hover:fill-accent h-5 w-5 " />
              </a>


            </div>
            <ul className="dark:text-white flex flex-wrap justify-center space-x-4 text-sm">
              <li>
                <Link
                  href="/terms-and-conditions"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link
                  href="/privacy"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy policy
                </Link>
              </li>
              <li>
                <Link
                  href="/giveaway-terms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Giveaway Terms
                </Link>
              </li>
            </ul>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default footer;
