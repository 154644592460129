import React, { useState, useEffect } from "react";
import Tabs from "../Tabs";

const SearchBar = ({ main }) => {

	const tld = [
		'.cryptoworld',
		'.investor',
		'.kingdom',
		'.brandname',
		'.coinz'
	]
	const sld = [
		'1.gamerpro',
		'Home.brand',
		'Nft.store',
		'Mikej.cryptobull',
		'Best.domain',
	]
	const [placeholderIndex, setPlaceholderIndex] = useState(0);
	const [typingIndex, setTypingIndex] = useState(0);
	const [typingText, setTypingText] = useState("");
	const [activeTab, setActiveTab] = useState('TLD');


	useEffect(() => {

		const currentPlaceholder = activeTab === 'TLD' ? tld[placeholderIndex] : sld[placeholderIndex]
		let currentIndex = 0;

		const typingTimer = setInterval(() => {
			if (currentIndex <= currentPlaceholder.length) {
				setTypingText(currentPlaceholder.slice(0, currentIndex));
				currentIndex++;
			} else {
				clearInterval(typingTimer);
				setTimeout(() => {
					setTypingIndex(0);
					setTypingText("");
					setPlaceholderIndex((prevIndex) =>
						(prevIndex + 1) % (activeTab === "TLD" ? tld.length : sld.length)
					);
				}, 2000);
			}
		}, 100);

		return () => {
			clearInterval(typingTimer);
		};
	}, [placeholderIndex]);

	return (
		<div className="pb-4 relative">
			<Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={['TLD', 'SLD']} />
			<div className={`relative gradient-box dark:bg-jacarta-900 bg-white border-2  rounded-lg flex items-stretch ${!main && 'z-[1]'}`}>
				<div className={`flex items-center w-full  ${main ? 'm-2' : 'm-0'} `}>
					<input
						type="text"
						placeholder={typingText}
						className="!outline-none p-4  text-jacarta-800 dark:text-white text-lg dark border-0 rounded-l-md  dark:bg-jacarta-700 bg-jacarta-100 focus:!border-transparent focus:!outline-transparent border-transparent focus:ring-0 dark:placeholder-white/70 placeholder-gray-600 w-full placeholder:text-sm md:placeholder:text-lg"
					/>
					<button className="px-8 font-bold py-4 bg-blue-500 text-white bg-gray-300 dark:bg-jacarta-800 h-full rounded-r-[5px] hover:bg-jacarta-base transition-all duration-200 ">
						Search
					</button>
				</div>
			</div>
		</div>

	);
};

export default SearchBar;
